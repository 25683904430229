// Si besoin d'IE 11, utiliser ceci :
// import 'stepup-stepdown-polyfill';

function NumberInput( args ) {
	let that = this;

	args = args || {};
	args.debug = args.debug || false;
	args.input = args.input || null
	args.maxLevelsToSearch = args.maxLevelsToSearch || 2;
	args.decreaseButtonSel = args.decreaseButtonSel || '.input-number-decrease';
	args.increaseButtonSel = args.increaseButtonSel || '.input-number-increase';

	this.args = args;

	this.init = function() {
		this.input = that.args.input || null

		that.getButtons();
		that.addEventListeners();
	};

	this.getButtons = function() {
		that.decreaseButton = null;
		that.increaseButton = null;

		// On monte jusqu'à X niveaux dans le DOM à la recherche de boutons +/-
		let levelsClimbed = 0;
		let curParent = that.input;

		while( curParent && levelsClimbed < ( that.args.maxLevelsToSearch + 1 ) && ! that.decreaseButton && ! that.increaseButton ) {
			curParent = curParent.parentElement;

			if( ! that.decreaseButton ) {
				that.decreaseButton = curParent.querySelector( that.args.decreaseButtonSel );
			}

			if( ! that.increaseButton ) {
				that.increaseButton = curParent.querySelector( that.args.increaseButtonSel );
			}

			++ levelsClimbed;
		}
	};

	this.changeInputValue = function( multiplier ) {
		multiplier = multiplier || 1;
		let changeEvent = new Event('change');
		let inputEvent = new Event('input');

		event.preventDefault();

		if( multiplier < 0 ) {
			that.input.stepDown( - ( multiplier ) );
		} else {
			that.input.stepUp( multiplier );
		}

		that.input.dispatchEvent( changeEvent );
		that.input.dispatchEvent( inputEvent );
	};

	this.addEventListeners = function() {
		if( that.decreaseButton ) {
			that.decreaseButton.addEventListener( 'click', function( event ) {
				event.preventDefault();
				that.changeInputValue( -1 );
			} );
		}
		if( that.increaseButton ) {
			that.increaseButton.addEventListener( 'click', function( event ) {
				event.preventDefault();
				that.changeInputValue( 1 );
			} );
		}
	};

	this.init();

	if( ! this.input ) {
		return [];
	} else {
		this.input._numberInput = this;
	}
}

export default NumberInput;