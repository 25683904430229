// S'assure qu'un hash contient bel et bien son symbole '#'.

export function normalizeHash( hash, withSymbol = true ) {
	let newHash = hash ? hash.replace( '#', '' ) : '';

	if( newHash && withSymbol ) {
		newHash = '#' + newHash;
	}

	return newHash;
}


// Prend un d'ID #hash, et renvoie une node HTMLElement (ou alors renvoie ce qu'on a reçu si c'en est déjà un)

export function getElementByHash( selector ) {
	let element;

	if( selector ) {
		if( 'string' == typeof selector ) {
			element = document.querySelector( normalizeHash( selector ) );
		} else {
			element = selector;
		}
	} else {
		element = null;
	}

	return element;
}


// Change le hash de la page, mais ne scroll pas vers ce hash.
// historyAction: 'push' (ajoute à l'historique) | 'replace' (n'ajoute pas à l'historique mais remplace l'état actuel)
export function changeHashWithoutScrolling( hash = null, historyStateObj = null, historyAction = 'push' ) {
	hash = normalizeHash( hash );
	let curHash = normalizeHash( window.location.hash );

	if( hash != curHash ) {
		let urlForHistory = hash ? hash : window.location.href.replace( /#.*$/, '' );

		if( 'push' == historyAction ) {
			history.pushState( historyStateObj, null, urlForHistory );
		} else if( 'replace' == historyAction ) {
			history.replaceState( historyStateObj, null, urlForHistory );
		}
	}
}


// elem : Élément DOM sur lequel on se pose la question
// offset : Nombre de pixels de grâce (nombre plus gros = plus tolérant)
// completelyInside : si true, l'élément doit être à 100% dans le viewport ; si false, il peut dépasser
// parent : par rapport à quoi? | défaut window/viewport

export function isInViewport( elem, offset, completelyInside, parent ) {
	offset = offset || ( -1 * window.fixedElHeight );
	completelyInside = completelyInside || false;
	parent = parent || window;

	let condition;
	let viewportBottom;
	let viewportTop;
	let elemTop;
	let elemBottom;

	if( 'string' == typeof elem ) {
		elem = document.querySelector( elem );
	}

	if( elem ) {
		if( parent == window ) {
			viewportTop = 0;
			viewportBottom = window.vHeight;
			elemTop = elem.getBoundingClientRect().top;
			elemBottom = elem.getBoundingClientRect().bottom;
		} else {
			viewportTop = parent.scrollTop;
			viewportBottom = parent.scrollTop + parent.offsetHeight;
			elemTop = elem.offsetTop;
			elemBottom = elem.offsetBottom + elem.offsetHeight;
		}


		if( completelyInside ) {
			condition = (
				( elemTop >= viewportTop + offset && elemTop < viewportBottom - offset )
				&& ( elemBottom >= viewportTop + offset && elemBottom < viewportBottom - offset )
			);
		} else {
			condition = (
				( elemTop >= viewportTop + offset && elemTop < viewportBottom - offset )
				|| ( elemBottom >= viewportTop + offset && elemBottom < viewportBottom - offset )
			);
		}
	} else {
		condition = null;
	}

	return condition;
}


// Fait apparaître des trucs quand ils entrent dans le viewport

export function smoothFadeIn( thisEl ) {
	if( ! thisEl.classList.contains( 'visible' ) && isInViewport( thisEl ) ) {
		thisEl.classList.add( 'visible' );
	}
}


// Change complètement l'URL de la page sans rediriger en enregistrant le changement dans l'historique.
// objet d'args :
// context('open'/'close') : 'open' = ouverture du popup / 'close' = retour à l'URL d'origine.

export function changeUrl( args ) {
	let returnVal;

	switch(args.context) {
		case 'open':
			window.changeUrlOriginalTitle = document.title;
			window.changeUrlOriginalUrl = window.location.href;
			window.changeUrlOriginalId = document.body.dataset.id || window.location.href;

			window.history.pushState(
				{
					id: 'popup-' + args.id
				},
				args.title,
				args.url
			);
			document.title = args.title;

			returnVal = true;
			break;

		case 'close':
			if( window.changeUrlOriginalId ) {
				window.history.pushState(
					{
						id: window.changeUrlOriginalId
					},
					window.changeUrlOriginalTitle,
					window.changeUrlOriginalUrl
				);
				document.title = window.changeUrlOriginalTitle;

				window.changeUrlOriginalTitle = null;
				window.changeUrlOriginalUrl = null;
				window.changeUrlOriginalId = null;

				returnVal = true;
				break;
			}
	}

	return returnVal;
}


// S'assure qu'aucun caractère réservé n'est dans une string pour regex

export function escapeRegExp(text) {
	if ( ! text || 'string' != typeof text ) {
		return '';
	}
	return text.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&');
}


// Est-ce que tous les éléments de cet array sont la même chose?

export function arrayAllEqual( array ) {
	return array.every( function( elementToCheck ) {
		return elementToCheck === array[0];
	} );
}


// Est-ce que toutes ces URLs sont la même chose?
// objet d'args :
// urls(array) : URLS à vérifier
// stripHash(bool) : retirer toutes les hash (#) des URLs avant de vérifier? | défaut true
// stripGet(bool) : retirer tous les paramètres get (?var=val) des URLs avant de vérifier? | défaut false

export function urlsAreSamePage( args ) {
	let getParamsRegex = /\?.+$/;
	let trailingSlashRegexes = [
		{
			regex: /\/$/,
			replacement: '',
		},
		{
			regex: /\/\?/,
			replacement: '?',
		},
		{
			regex: /\/#/,
			replacement: '#',
		}
	];
	let nakedUrls = [];
	args = args || {};

	args.urls = args.urls || [];
	args.stripHash = args.stripHash || true;
	args.stripGet = args.stripGet || false;

	if( 'string' == typeof args.urls ) {
		args.urls = [ args.urls ];
	}

	// Une seule URL ? On compare avec celle de la page actuelle.
	if( Array.isArray( args.urls ) && 1 == args.urls.length ) {
		args.urls.push( window.location.href );
	}

	args.urls.forEach( function( thisUrl ) {
		let hashIndex = thisUrl.indexOf( '#' );
		let urlHash = -1 !== hashIndex ? thisUrl.substring( hashIndex ) : '';
		let nakedUrl = thisUrl;

		if( args.stripHash ) {
			nakedUrl = nakedUrl.replace( urlHash, '' );
		}

		if( args.stripGet ) {
			nakedUrl = nakedUrl.replace( getParamsRegex, '' );
		}

		trailingSlashRegexes.forEach( function( thisRegex ) {
			nakedUrl.replace( thisRegex.regex, thisRegex.replacement );
		} );

		nakedUrls.push( nakedUrl );
	} );

	return arrayAllEqual( nakedUrls );
}


// Combien de chiffres après la virgule le chiffre a-t-il ?
// https://stackoverflow.com/a/27082406/1995518

export function countDecimals( value ) {
	if( Math.floor(value) !== value ) {
		return value.toString().split(".")[1].length || 0;
	}
	return 0;
}


// Cet élément fait-il partie d'un élément no-scripts? Si oui on initialisera pas toutes sortes d'affaires.

export function scriptsOk( element ) {
	return element.closest( '.no-scripts' ) ? false : true;
}